import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted } from "vue";
import AppLoading from "@/views/pages/components/AppLoading.vue";
import { useNhostClient } from "@nhost/vue";
import { usePrimeVue } from "primevue/config";
import { useI18n } from "vue-i18n";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var _useI18n = useI18n(),
      t = _useI18n.t;
    onMounted(function () {
      var primevue = usePrimeVue();
      primevue.config.locale.dayNamesShort = t("locale.dayNamesShort").split(" ");
      primevue.config.locale.dayNamesMin = t("locale.dayNamesMin").split(" ");
      primevue.config.locale.monthNamesShort = t("locale.monthNamesShort").split(" ");
      primevue.config.locale.dayNames = t("locale.dayNames").split(" ");
      primevue.config.locale.monthNames = t("locale.monthNames").split(" ");
      document.getElementById("loaderID").style.display = "none";
    });
    return function (_ctx, _cache) {
      var _component_Toast = _resolveComponent("Toast");
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Toast), _createVNode(_component_router_view), _createVNode(AppLoading)], 64);
    };
  }
};